import {
  DASHBOARD_COMPARED_TO_WATERFALLS,
  DASHBOARD_CONSUMER_DEMOGRAPHICS,
  DASHBOARD_ENGAGEMENTS_QUANTITY,
  DASHBOARD_RECENT_ACTIVITY,
  DASHBOARD_TOTAL_ACTIVITY,
} from 'environment/constants/url';
import { RecentActivityItem } from 'store/models/dashboard';

import axios from '../axios';

// TOTAL ACTIVITY

export const getDashboardTotalActivityRequest = (businessUnitId: string) => {
  return axios.get(DASHBOARD_TOTAL_ACTIVITY(businessUnitId).TOTAL_ACTIVITY);
};

//  ENGAGEMENTS QUANTITY

export const getDashboardEngagementsQuantityRequest = (
  businessUnitId: string,
) => {
  return axios.get(
    DASHBOARD_ENGAGEMENTS_QUANTITY(businessUnitId).ENGAGEMENTS_QUANTITY,
  );
};

// RECENT ACTIVITY

export const getDashboardRecentActivityRequest = (
  businessUnitId: string,
  lastCreatedAt?: string,
) => {
  const params = {
    lastCreatedAt,
    limitPerPage: 10,
  };

  return axios.get<{ recentActivity: RecentActivityItem[] }>(
    DASHBOARD_RECENT_ACTIVITY(businessUnitId).RECENT_ACTIVITY,
    { params },
  );
};

export const getDashboardAllRecentActivityRequest = (
  businessUnitId: string,
) => {
  return axios.get<number>(
    DASHBOARD_RECENT_ACTIVITY(businessUnitId).ALL_RECENT_ACTIVITY_COUNTER,
  );
};

// CONSUMER DEMOGRAPHICS

export const getDashboardConsumerDemographicsRequest = (
  businessUnitId: string,
) => {
  return axios.get(
    DASHBOARD_CONSUMER_DEMOGRAPHICS(businessUnitId).CONSUMER_DEMOGRAPHICS,
  );
};

// COMPARED TO WATERFALLS

export const getDahboardComparedToWaterfallsRequest = (
  businessUnitId: string,
) => {
  return axios.get(
    DASHBOARD_COMPARED_TO_WATERFALLS(businessUnitId).COMPARED_TO_WATERFALLS,
  );
};
