import { CONSUMER_DEMOGRAPHICS_URL } from 'environment/constants/url';

import axios from '../axios';

export const getConsumerDemographicsGraphRequest = (
  businessUnitId: string,
  gte: string | undefined,
  lte: string | undefined,
) => {
  return axios.get(CONSUMER_DEMOGRAPHICS_URL(businessUnitId).GRAPH, {
    params: {
      gte,
      lte,
    },
  });
};

export const getConsumerDemographicsCountersRequest = (
  businessUnitId: string,
  gte: string | undefined,
  lte: string | undefined,
) => {
  return axios.get(CONSUMER_DEMOGRAPHICS_URL(businessUnitId).COUNTERS, {
    params: {
      gte,
      lte,
    },
  });
};
