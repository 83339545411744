import { createModel } from '@rematch/core';

import {
  getDahboardComparedToWaterfallsRequest,
  getDashboardAllRecentActivityRequest,
  getDashboardConsumerDemographicsRequest,
  getDashboardEngagementsQuantityRequest,
  getDashboardRecentActivityRequest,
  getDashboardTotalActivityRequest,
} from '../../environment/api/services/dashboard';
import {
  CommentOwners,
  EndorseOwners,
  EngagementTypes,
  MediaTypes,
  ShareOwners,
} from '../../environment/constants';

import type { RootModel } from '.';

export interface ITotalActivity {
  engagements: {
    change: number;
    total: number;
    last7days: {
      date: string;
      count: number;
    }[];
  };
  engagers: {
    change: number;
    total: number;
    last7days: {
      date: string;
      count: number;
    }[];
  };
}

export interface IEngagementsQuantityForDay {
  date: string;
  count: number;
  change: null | number;
}

export type RecentActivityItem = {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  mediaId: string;
  rating: number;
  createdAt: string;
} & (
  | {
      owner: undefined;
      ownerMediaType: undefined;
      engagementType:
        | EngagementTypes.PageVisit
        | EngagementTypes.ForumTag
        | EngagementTypes.StoryTag
        | EngagementTypes.Review;
    }
  | {
      owner: EndorseOwners;
      ownerMediaType: MediaTypes;
      engagementType: EngagementTypes.Watch;
    }
  | {
      owner: EndorseOwners;
      ownerMediaType: undefined;
      engagementType: EngagementTypes.Like;
    }
  | {
      owner: CommentOwners;
      ownerMediaType: undefined;
      engagementType: EngagementTypes.Comment;
    }
  | {
      owner: ShareOwners;
      ownerMediaType: undefined;
      engagementType: EngagementTypes.Share;
    }
);

export interface IRecentActivity {
  totalCount: number;

  recentActivity: RecentActivityItem[];
  hasNextPage: boolean;
}

export interface IConsumerAgeGroup {
  group: {
    min: number;
    max: number;
  };
  quantity: number;
}

export interface IConsumerDemographics {
  ageGroups: IConsumerAgeGroup[];
}

export interface IComparedToWaterfalls {
  totalItems: number;
  featuredPercentage: number;
  nonFeaturedPercentage: number;
  percentageChangeToLastWeek: number;
  totalEngagementScore: number;
  featuredEngagementScorePercentage: number;
  nonFeaturedEngagementScorePercentage: number;
  percentageEngagementScoreChangeToLastWeek: number;
}

interface IDashboardState {
  totalActivity: ITotalActivity;
  engagementsQuantity: IEngagementsQuantityForDay[];
  recentActivity: IRecentActivity;
  consumerDemographics: IConsumerDemographics;
  comparedToWaterfalls: IComparedToWaterfalls;
  addBusinessModalOpen: boolean;
}

const initialState: IDashboardState = {
  totalActivity: {
    engagements: {
      total: 0,
      change: 0,
      last7days: [],
    },
    engagers: {
      total: 0,
      change: 0,
      last7days: [],
    },
  },
  engagementsQuantity: [],
  recentActivity: {
    totalCount: 0,

    recentActivity: [],
    hasNextPage: true,
  },
  consumerDemographics: {
    ageGroups: [
      {
        group: {
          min: 0,
          max: 0,
        },
        quantity: 0,
      },
    ],
  },
  comparedToWaterfalls: {
    totalItems: 0,
    featuredPercentage: 0,
    nonFeaturedPercentage: 0,
    percentageChangeToLastWeek: 0,
    totalEngagementScore: 0,
    featuredEngagementScorePercentage: 0,
    nonFeaturedEngagementScorePercentage: 0,
    percentageEngagementScoreChangeToLastWeek: 0,
  },
  addBusinessModalOpen: false,
};

export const dashboard = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updatedTotalActivity: (state, payload) => {
      return {
        ...state,
        totalActivity: payload,
      };
    },
    updateEngagementsQuantity: (state, payload) => {
      return {
        ...state,
        engagementsQuantity: payload,
      };
    },

    setRecentActivityTotalCount: (state, payload: number) => {
      return {
        ...state,
        recentActivity: {
          ...state.recentActivity,
          totalCount: payload,
        },
      };
    },

    setRecentActivity: (state, payload: RecentActivityItem[]) => {
      return {
        ...state,
        recentActivity: {
          ...state.recentActivity,
          recentActivity: [...state.recentActivity.recentActivity, ...payload],
        },
      };
    },

    setRecentActivityHasNextPage: (state, hasNextPage: boolean) => {
      return {
        ...state,
        recentActivity: { ...state.recentActivity, hasNextPage },
      };
    },

    updateConsumerDemographics: (state, payload) => {
      return {
        ...state,
        consumerDemographics: payload,
      };
    },

    updateComparedToWaterfalls: (state, payload) => {
      return {
        ...state,
        comparedToWaterfalls: payload,
      };
    },

    setAddBusinessModalOpen: (state) => {
      return {
        ...state,
        addBusinessModalOpen: !state.addBusinessModalOpen,
      };
    },

    resetState: () => ({ ...initialState }),
  },
  effects: (dispatch) => ({
    getDashboardTotalActivity: async (businessUnitId: string) => {
      try {
        const { data } = await getDashboardTotalActivityRequest(businessUnitId);
        dispatch.dashboard.updatedTotalActivity(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getDashboardEngagementsQuantity: async (businessUnitId: string) => {
      try {
        const { data } =
          await getDashboardEngagementsQuantityRequest(businessUnitId);

        dispatch.dashboard.updateEngagementsQuantity(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getAllRecentActivity: async (businessUnitId: string) => {
      try {
        const { data } =
          await getDashboardAllRecentActivityRequest(businessUnitId);

        dispatch.dashboard.setRecentActivityTotalCount(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getDashboardRecentActivity: async (businessUnitId: string) => {
      try {
        dispatch.dashboard.setRecentActivityHasNextPage(true);

        const {
          data: { recentActivity },
        } = await getDashboardRecentActivityRequest(businessUnitId);

        dispatch.dashboard.setRecentActivity(recentActivity);
        return Promise.resolve(recentActivity);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getMoreDashboardRecentActivity: async (_: void, state) => {
      try {
        const businessUnitId = state.units.currentBusinessUnitId;

        const lastCreatedAt =
          state.dashboard.recentActivity.recentActivity.at(-1)?.createdAt;

        if (!lastCreatedAt || !businessUnitId) {
          return;
        }

        const {
          data: { recentActivity },
        } = await getDashboardRecentActivityRequest(
          businessUnitId,
          lastCreatedAt,
        );

        if (!recentActivity.length) {
          dispatch.dashboard.setRecentActivityHasNextPage(false);
        } else {
          dispatch.dashboard.setRecentActivity(recentActivity);
        }

        // todo: Can't neither resolve nor just return the value due to
        // Typescript's circular referencing error of Rematch state.
        // –> Need further investigation

        // return Promise.resolve(recentActivity)
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getDashboardConsumerDemographics: async (businessUnitId: string) => {
      try {
        const { data } =
          await getDashboardConsumerDemographicsRequest(businessUnitId);

        dispatch.dashboard.updateConsumerDemographics(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getDashboardComparedToWaterfalls: async (businessUnitId: string) => {
      try {
        const { data } =
          await getDahboardComparedToWaterfallsRequest(businessUnitId);

        dispatch.dashboard.updateComparedToWaterfalls(data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
});
