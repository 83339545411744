import { createModel } from '@rematch/core';

import {
  completeClaim,
  setCategoryTag,
  setClaimCredetials,
  setClaimPayment,
  setClaimSubscription,
  setLabels,
  setQuestions,
  startClaim,
  verifyEmail,
} from 'environment/api/services/claim';
import {
  CategoriesOfTags,
  PartialQuestion,
} from 'environment/types/api/shared';
import {
  BUSINESS_UNIT_CATEGORY_TAG,
  BUSINESS_UNIT_CLAIM_SOURCE,
  BUSINESS_UNIT_CLAIM_STAGES,
  ClaimData,
  ClaimDataResponse,
  PromotionStats,
} from 'environment/types/claim';
import { questions, tags } from 'hooks/forms/default-values';

import { RootModel } from '.';

interface State
  extends Omit<ClaimData, 'businessUnitPickedLabelsId' | 'createdAt'> {
  stats: PromotionStats | null;
  onboardingType: BUSINESS_UNIT_CLAIM_SOURCE;
  copyData: boolean;
  loading: boolean;
}

const initialState: State = {
  onboardingType: BUSINESS_UNIT_CLAIM_SOURCE.from_landing_page,
  copyData: false,
  businessUnit: null,
  businessUnitId: null,
  businessUnitPickedLabels: tags,
  categoryTag: null,
  email: null,
  email_verified: false,
  id: null,
  password: null,
  isShadow: false,
  isByNolemon: false,
  questions,
  stages: [],
  subscription: null,
  cardToken: null,
  stats: null,
  loading: false,
};

export const onboarding = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setOnboarding: (state, payload: Partial<State>) => {
      return { ...state, ...payload };
    },
    resetOnboarding: () => {
      return initialState;
    },
    setLoading: (state, loading: boolean) => {
      return { ...state, loading };
    },
  },
  effects: (dispath) => ({
    async startUnitClaim(
      _: void,
      state,
    ): Promise<BUSINESS_UNIT_CLAIM_STAGES[] | void> {
      try {
        dispath.onboarding.setLoading(true);
        const { data } = await startClaim({
          businessUnitId: state.onboarding.businessUnit?.id as string,
          businessClaimProgressType: state.onboarding.onboardingType,
        });
        dispath.onboarding.setOnboarding(data);
        return Promise.resolve(data.stages);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        dispath.onboarding.setLoading(false);
      }
    },
    async setClaimSubscription(stripeSubscriptionId: string, state) {
      try {
        dispath.onboarding.setLoading(true);
        const { data } = await setClaimSubscription({
          id: state.onboarding.id as string,
          stripeSubscriptionId,
          businessClaimProgressType: state.onboarding.onboardingType,
        });
        dispath.onboarding.setOnboarding(data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        dispath.onboarding.setLoading(false);
      }
    },
    async setClaimPayment(cardToken: string, state) {
      try {
        dispath.onboarding.setLoading(true);
        const { data } = await setClaimPayment({
          id: state.onboarding.id as string,
          cardToken,
          businessClaimProgressType: state.onboarding.onboardingType,
        });
        dispath.onboarding.setOnboarding(data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        dispath.onboarding.setLoading(false);
      }
    },
    async setClaimCredetials(
      { email, password }: { email: string; password: string },
      state,
    ) {
      try {
        dispath.onboarding.setLoading(true);
        const { data } = await setClaimCredetials({
          id: state.onboarding.id as string,
          email,
          password,
          businessClaimProgressType:
            BUSINESS_UNIT_CLAIM_SOURCE.from_landing_page,
        });
        dispath.onboarding.setOnboarding({ ...data, password });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        dispath.onboarding.setLoading(false);
      }
    },
    async verifyClaimCredetials(
      code: string,
      state,
    ): Promise<ClaimDataResponse | void> {
      try {
        dispath.onboarding.setLoading(true);
        const { data } = await verifyEmail({
          id: state.onboarding.id as string,
          email: state.onboarding.email as string,
          code,
        });
        dispath.onboarding.setOnboarding(data.progress);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        dispath.onboarding.setLoading(false);
      }
    },
    async setClaimCategoryTag(categoryTag: BUSINESS_UNIT_CATEGORY_TAG, state) {
      try {
        dispath.onboarding.setLoading(true);
        const { data } = await setCategoryTag({
          registrationProgressId: state.onboarding.id as string,
          source: state.onboarding.onboardingType,
          categoryTag,
        });
        dispath.onboarding.setOnboarding(data.progress);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        dispath.onboarding.setLoading(false);
      }
    },
    async setClaimTags(labels: CategoriesOfTags, state) {
      try {
        dispath.onboarding.setLoading(true);
        const { data } = await setLabels({
          registrationProgressId: state.onboarding.id as string,
          labels,
          source: state.onboarding.onboardingType,
        });
        dispath.onboarding.setOnboarding(data.progress);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        dispath.onboarding.setLoading(false);
      }
    },
    async setClaimQuestions(questions: PartialQuestion[], state) {
      try {
        dispath.onboarding.setLoading(true);
        const { data } = await setQuestions({
          registrationProgressId: state.onboarding.id as string,
          questions,
          source: state.onboarding.onboardingType,
        });
        dispath.onboarding.setOnboarding(data.progress);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        dispath.onboarding.setLoading(false);
      }
    },
    async completeClaim(_: void, state) {
      try {
        dispath.onboarding.setLoading(true);
        const { data } = await completeClaim(
          state.onboarding.id as string,
          state.onboarding.onboardingType,
        );
        if (data) {
          localStorage.setItem('access_token', data.id_token);
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        dispath.onboarding.setLoading(false);
      }
    },
  }),
});
